import React from "react";
import ReactDOM from "react-dom";
import { PdfApp } from "./PdfApp";
import "./styles.css";
import "./styles/styles.scss";
import AOS from 'aos';
import "aos/dist/aos.css";
import 'aos/dist/aos.js';
import 'animate.css';
AOS.init();

ReactDOM.render(
    <React.StrictMode>
        <PdfApp />
    </React.StrictMode>,
    document.getElementById("root"),
    document.addEventListener("DOMContentLoaded", function () {
        setTimeout(function () { AOS.refresh(); }, 1000)
    }),
    document.addEventListener("load", function () {
        AOS.refresh();
    }),
);
