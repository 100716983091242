import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

export const Home = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const body = await fetch(process.env.REACT_APP_API_URL + '/data', {
        method: 'GET'
      }).then(response => response.json());
      setData(body.data);
      setIsLoading(false);
    }
    fetchData();
  }, [])


  const [file, setFile] = useState(null);
  const [dragActive, setDragActive] = useState(false);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFile(e.dataTransfer.files[0]);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const handleButtonClick = () => {
    document.getElementById('input-file-upload').click();
  };

  const uploadPdf = async () => {
    if (file) {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('pdf', file);
      try {
        const body = await fetch(process.env.REACT_APP_API_URL + '/data', {
          method: 'POST',
          body: formData
        }).then(response => response.json());
        if (body.ok) {
          setData(body.data);
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Error al subir el PDF:', error);
      }
    }
  };

  const handleDelete = async (id) => {
    setIsLoading(true);
    const body = await fetch(process.env.REACT_APP_API_URL + `/data/${id}`, {
      method: 'DELETE'
    }).then(response => response.json());
    if (body.ok) {
      setData(body.data);
      setIsLoading(false);
    }
  };

  const exportXlsx = async (id) => {
    Swal.fire({
      title: 'Espere ...',
      onBeforeOpen() {
        Swal.showLoading()
      },
      onAfterClose() {
        Swal.hideLoading()
      },
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false
    })
    await fetch(process.env.REACT_APP_API_URL + `/data/export`, {
      method: 'GET'
    }).then(res => res.blob()).then(blob => {
      // Create a URL for the blob
      const url = window.URL.createObjectURL(blob);
      // Create a temporary link element
      const a = document.createElement('a');
      // Set the href attribute to the blob URL
      a.href = url;
      // Set the download attribute to specify the filename
      a.download = 'data.xlsx';
      // Append the link to the body
      document.body.appendChild(a);
      // Click the link programmatically to trigger the download
      a.click();
      // Remove the link from the body
      document.body.removeChild(a);
      // Release the object URL
      window.URL.revokeObjectURL(url);
      Swal.close();
    })
  };

  const handleDeleteAll = async (id) => {
    setIsLoading(true);
    const body = await fetch(process.env.REACT_APP_API_URL + `/data/delete/all`, {
      method: 'DELETE'
    }).then(response => response.json());
    if (body.ok) {
      setData([]);
      setIsLoading(false);
    }
  };
  return (
    <div className='d-flex justify-content-center align-items-center vh-100'>
      <div className="col login-form-2 m-2">
        <div className='d-flex flex-column justify-content-center align-items-center'>
          <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
            <input
              type="file"
              id="input-file-upload"
              onChange={handleChange}
            />
            <label
              id="label-file-upload"
              htmlFor="input-file-upload"
              className={dragActive ? "drag-active" : ""}
            >
              <div>
                <p>Arrastra y suelta tu archivo PDF aquí o</p>
                <button className="upload-button" onClick={handleButtonClick}>Selecciona un archivo</button>
              </div>
            </label>
            {dragActive && <div
              id="drag-file-element"
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            ></div>}
          </form>
          {file && <p className='text-center mt-3'>Archivo seleccionado: {file.name}</p>}
        </div>
        <div className='container d-flex flex-column justify-content-center align-items-center'>
          <button className='btn btn-success text-bold mt-4' onClick={() => { uploadPdf() }}>Subir</button>
        </div>
      </div>
      <div className="col login-form-2 m-2 vh-80 d-flex flex-column justify-content-center align-items-center">
        {
          isLoading
            ?
            <div className='d-flex flex-column justify-content-center align-items-center'>
              <div class="loader"></div>
            </div>
            :
            <div className='mb-2'>
              <div className='d-flex flex-column justify-content-center align-items-center'>
                <h1 className='text-center'>Data</h1>
                <div className='d-flex align-items-center gap-2 mb-3'>
                  <button className='btn btn-success text-bold' onClick={() => { exportXlsx() }}><i className='fa fa-file-excel'></i> Exportar</button>
                  <button className='btn btn-danger text-bold' onClick={() => { handleDeleteAll() }}><i className='fa fa-trash'></i> Borrar todo</button>
                </div>
              </div>
              <div className='table-responsive vh-50'>
                {data && <table className="table table-bordered table-hover bg-light overflow-scroll">
                  <thead className='bg-dark text-light align-middle'>
                    <tr>
                      <th className="text-center" scope="col">Impuesto</th>
                      <th className="text-center" scope="col">Nro. Fact.</th>
                      <th className="text-center" scope="col">Monto Retención</th>
                      <th className="text-center" scope="col">Monto Fact.</th>
                      <th className="text-center" scope="col">Borrar</th>
                    </tr>
                  </thead>
                  <>
                    {data.length > 0 && data.map(item => {
                      return (
                        <tbody key={item.id}>
                          <tr>
                            <td className="text-center">{item.impuesto}</td>
                            <td className="text-center">{item.fecha_nro}</td>
                            <td className="text-center">{item.monto_total}</td>
                            <td className="text-center">{item.monto_fact}</td>
                            <td className="text-center"><button className='btn btn-danger' onClick={() => handleDelete(item.id)}><i className='fa fa-trash'></i></button></td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </>
                </table>}
              </div>
            </div>
        }

      </div>
    </div>
  );
};